.loader-kg2fkgn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    border-top: 2px solid #856404;
    border-right: 2px solid transparent;
    box-sizing: border-box;
    animation: rotation-kg2fkgn 1s linear infinite;
}

.loader-kg2fkgn::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-left: 2px solid #5D87FF;
    border-bottom: 2px solid transparent;
    animation: rotation-kg2fkgn 0.5s linear infinite reverse;
}

@keyframes rotation-kg2fkgn {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}