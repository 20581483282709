.rcp {
    border-radius: 5px 5px 0 0;
}

    .rcp-saturation {
        border-radius: 3px 3px 0 0;
    }

.shape-color-picker-submit {
    padding: 0 10px 15px 10px;
    background-color: #121212;
    border-radius: 0 0 3px 3px;
}