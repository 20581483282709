@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseFade {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

:root {
  --bs-body-font-family: "Plus Jakarta Sans", "Open Sans", Arial, sans-serif;
}

.chrome-picker {
    font-family: "Plus Jakarta Sans", "Open Sans", Arial, sans-serif !important;
}

.block-bg-hovered:hover {
    background-color: rgba(0,0,0,0.05);
}

/*** MAP ***/

.leaflet-fade-anim .leaflet-popup {
    transition: none;
}

.leaflet-loc-marker-dot {
    background-color: #388E3C;
    border-radius: 10px;
    border: 2px solid rgba(255,255,255,0.75);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.leaflet-loc-marker-dot-filter-1 {background-color: #388E3C;}
.leaflet-loc-marker-dot-filter-2 {background-color: #D84315; width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #D84315;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);}
.leaflet-loc-marker-dot-filter-3 {background-color: #1976D2;}
.leaflet-loc-marker-dot-filter-4 {background-color: #1976D2;}

.leaflet-loc-popup-content-wrapper {
    min-width: 160px;
}

.leaflet-loc-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

    .leaflet-loc-marker svg {
        --loc-marker-color: yellow;
        width: 100%;
        height: 100%;
    }

        .leaflet-loc-marker path, .leaflet-loc-marker circle {
            fill: var(--loc-marker-color);
        }
        .leaflet-loc-marker.marker-positive path, .leaflet-loc-marker.marker-positive circle {
            fill: var(--loc-marker-color);
        }

/*** GENERAL STYLES ***/

.p-small-margins p {
    margin-bottom: 0.5rem;
}

.form-check-input {
    cursor: pointer;
}

.form-control.with-icon {
    padding-right: 46px;
}

.form-control-icon {
    position: absolute;
    top: 11px;
    right: 14px;
    cursor: pointer;
}

.m-block-gap {
    margin-bottom: 30px;
}

/*** MAP STYLES ***/

#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-fluid.map-cover {
    padding: 70px 0 0 0 !important;
    min-height: 100vh;
    height: 100vh;
}

.map-panel {
    width: 100%;
    height: 100%;
    position: relative;
}
.map-panel.ruler-active #map-container {
    cursor: crosshair;
}
#map-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
}

.map-area {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    pointer-events: none;

    position: absolute;
    z-index: 40;
}
.map-area > * {
    pointer-events: auto;
}

.map-area.map-corner-top-left {top: 0; left: 0;}
.map-area.map-corner-top-right {top: 0; right: 0; align-items: end;}
.map-area.map-corner-bottom-left {bottom: 0; left: 0; padding-bottom: 32px;}
.map-area.map-corner-bottom-right {bottom: 0; right: 0; padding-bottom: 32px;}
.map-area.map-top {top: 0; left: 50%; transform: translate(-50%, 0); width: 30%;}
.map-area.project-info {top: 0; left: calc(17.5% + 56px); transform: translate(calc(-50% - 16px), 0); width: 25%;}

.map-area .shape-info-panel-container {
    pointer-events: none;
}
    .map-area .shape-info-panel-container > * {
        pointer-events: auto;
    }

.map-area .btn-group, .map-area .btn-group-vertical {
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

.icon-button {
    padding: 11px;
    border: 0;
    background: none;
    outline: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    display: none;

    color: #856404;
}
.onmap-info-panel:hover .icon-button {
    display: flex;
}

.icon-button.passive {
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    display: flex;
}

.form-control.map-info-control {
    padding: 0 !important;
    border: 0 !important;
    border-bottom: 1px dotted #856404 !important;
    border-radius: 0;
}

    .form-control.map-info-control h1 sup {

    }

.form-control.input-onmap {
    padding: 0 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);

    line-height: 40px;
    font-size: 14px;
    color: #5a6a85;

    height: 40px;
}

    .form-control.input-onmap .rbt-input-main.form-control.rbt-input {
        margin: 0;
        background-color: transparent;
        border-radius: 0;
        border: 0;
        box-shadow: none;

        height: auto;
    }

.btn-onmap {
    padding: 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: #5a6a85;

    transition: background-color 0.3s, border-color 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px !important;
    height: 40px;
}
.btn-onmap:hover {
    background-color: #f4f7fb;
    border-color: #d0d0d0;
}
.btn-onmap:active, .btn-onmap.active, .btn-onmap:active:focus, .btn-onmap.active:focus {
    background-color: #f0f3f7;
    border-color: #c0c0c0;
}

.map-area .dropdown-menu {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);

    transform: translate(0, 48px);
    right: 0;
    top: 0;
    z-index: 120;
}
.map-area .dropdown-cover {
    background-color: transparent;
    content: "";

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    z-index: 110;
}

.onmap-info-panel {
    padding: 6px 12px;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);

    line-height: 1.6;
    font-size: 14px;
    color: #272727;

    min-width: 200px;
    max-width: 300px;

    position: relative;
}
.onmap-info-panel.style-warning, .panel-style-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.btn.panel-style-warning:hover {
    background-color: #ecd393;
    border-color: #dac281;
    color: #856404;
}

.map-area.project-info .onmap-info-panel {
    max-width: 100%;
}

    .map-area.project-info .onmap-info-panel .icon-button {
        padding: 9px 11px;
        top: 0;
        transform: none;
    }

.onmap-info-panel.map-info-panel {
    min-width: 825px;
}
.onmap-info-panel.map-info-panel.short-form {
    min-width: 250px;
}

    .onmap-info-panel .btn-group.shape-toolbar {
        background-color: #fff3cd;
        box-shadow: none;
        width: 200px;
        float: right;
    }

        .onmap-info-panel .btn-group.shape-toolbar > .btn {
            flex: none
        }

.editor-container .toolbar, .editor-container .editor-inner {
    background: none;
}

/*** CARD STYLES ***/

.card.card-sq-image a.sq-image {
    width: 100%;
    position: relative;
    display: block;
}
.card.card-sq-image a.sq-image::before {
    content: "";
    display: block;
    padding-top: 59%;
}

    .card.card-sq-image a.sq-image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

.card-add {
    height: calc(100% - 30px);
}

    .card-add i.ti {
        font-size: 120px;
    }

.one-liner {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.one-liner::after {
    background: linear-gradient(to left, white, transparent);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
}

.business-top-profile {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
}

.editable, .editable * {
    cursor: pointer !important;
}
.editable:hover {
    background-color: var(--bs-light);
}
.d-editable {visibility: hidden;}
.editable:hover .d-editable {visibility: visible;}

.bordered-tip {
    padding-left: 1.5rem;
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-bottom: 0;
}
@media screen and (max-width: 768px) {
    .bordered-tip {
        padding-left: 0;
        border-left: 0;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

table tr.row-passive {
    opacity: 0.3;

    transition: opacity 0.3s;
}
table tr.row-passive:hover {
    opacity: 1;
}

.table-primary {
    --bs-table-color: #ffffff;
    --bs-table-bg: rgb(93, 135, 255);
    --bs-table-active-bg: rgb(93, 135, 255);
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: rgb(93, 135, 255);
    --bs-table-hover-color: #ffffff;
}

.k-simple-form {
    max-width: 800px;
}

.image-upload-thumbnail {
    width: 32px;
    height: 32px;
}

    .image-upload-thumbnail img {
        width: 32px;
        height: 32px;

        object-fit: contain;
        object-position: 50% 50%;
    }

.card.loading table {
    animation: pulseFade 2s ease-in-out infinite;
}

.card.loading::after, .modal.loading::after {
    content: "";
    background-color: rgba(255,255,255,0.75);

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;
}

.card.loading .card-loading, .modal.loading .card-loading {
    margin: -16px 0 0 -16px;

    width: 32px;
    height: 32px;

    animation: spin 2s linear infinite;

    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 10;
}

    .card.loading .card-loading svg, .modal.loading .card-loading svg {
        width: 100%;
        height: 100%;
    }

        .card.loading .card-loading svg path, .modal.loading .card-loading svg path {
            fill: var(--bs-primary);
        }

.card .project-logo {
    border: 3px #fff solid;
    border-radius: 35px;

    width: 70px;
    height: 70px;
    display: block;

    object-fit: cover;
    object-position: center;

    left: 14px;
    bottom: -35px;
    position: absolute;

    overflow: hidden;
}

.cluster-styled {
    --cluster-color: #ff0000;
    --cluster-text-color: #ffffff;
    margin: -16px 0 0 -16px;
    border-radius: 16px;
    background-color: var(--cluster-color);
    box-shadow: 0 0 16px 16px var(--cluster-color);

    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--cluster-text-color);
}

.leaflet-measure-path-measurement {
    font-size: 13px !important;
    font-weight: 500;
}

    .leaflet-measure-path-measurement > div {
        margin-top: -75% !important;
    }
