.setbacks-active-vertex {
    border: 2px #ffffff solid;
    border-radius: 50px;
    background: #558B2F;
}

.setbacks-passive-vertex {
    border: 1px #ffffff solid;
    border-radius: 50px;
    background: #0B61A4;
}

    .leaflet-container .leaflet-marker-pane .tb-setbacks-delete img {width: 28px;}
    .leaflet-container .leaflet-marker-pane .tb-setbacks-change img {width: 27px; margin-left: -1px;}
    .leaflet-container .leaflet-marker-pane .tb-setbacks-edit img {width: 30px;}